import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import contactUs_img from "../assets/contact_us.png";
import { FaFacebook, FaInstagram, FaYoutube, FaLinkedin, FaWhatsapp } from "react-icons/fa";

const ContactPage = () => {
  const infoItems = [
    {
      icon: "📞",
      title: "Contact Number",
      description:
        "Our team is ready to assist you with any inquiries. Reach us by phone at:",
      highlight: "+91 7756043707",
      link: "#",
    },
    {
      icon: "💬",
      title: "Email Us",
      description: "For questions, support, or more information, email us at:",
      highlight: "info@inttrvu.ai",
      link: "mailto:info@inttrvu.ai",
    },
    {
      icon: "📍",
      title: "Office Address",
      description:
        "Office No. 307 , Acharya House, Plot No.24 Sr.No.19/12/1, Bavdhan, Pune, Maharashtra-411021",
      highlight: "Get Location",
      link: "https://maps.app.goo.gl/n5LQUiKSoDe9uw9W8",
    },
  ];

  return (
    <div className="bg-gray-50">
      <Header />

      <div className="relative h-[25rem] md:h-[35rem] ">
        {/* Image */}
        <img
          className="w-full h-full mt-12 object-fill"
          src={contactUs_img}
          alt="Background"
        />
        {/* Overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

        {/* Content */}
        <div className="absolute inset-0 flex items-center px-4 md:px-16">
          <div className="text-white max-w-2xl">
            <h2 className="text-3xl md:text-5xl font-bold mb-4">Contact Us</h2>
            <p className="text-lg md:text-xl">
              We’re here to help! If you have any questions, comments or need
              assistance, please don’t hesitate to get in touch with us. Our
              team is ready to assist you with any inquiries you may have.
            </p>
          </div>
        </div>
      </div>





      {/* Contact Info Section */}
      <div className="container mx-auto px-4 py-12 bg-gray-50">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {infoItems.map((item, index) => (
            <div
              key={index}
              className="bg-white border border-black p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-200"
            >
              <div className="text-4xl text-blue-500 mb-4">{item.icon}</div>
              <h3 className="text-lg font-semibold mb-2">{item.title}</h3>
              <p className="text-black mb-4">{item.description}</p>
              <a
                href={item.link}
                className="text-blue-500 font-medium hover:underline flex items-center"
              >
                {item.highlight} <span className="ml-1">→</span>
              </a>
            </div>
          ))}
        </div>
      </div>

      {/* Connect with Us Section */}
      <div className="bg-black h-64 text-white py-16 px-4 md:px-16 flex">
 <div className="">
  <h2 className="text-3xl font-semibold text-center md:text-left">
    Connect with Us
  </h2>
  <p className="mt-6 pl-10 text-center md:text-left text-lg md:text-xl">
    Stay connected and follow us on our social media platforms for the
    latest updates.
  </p>
  </div> 
  <div className="flex pl-28 mt-12 space-x-8">
    <a
      href="https://www.facebook.com/people/inttrvuai/100092270186906/?mibextid=LQQJ4d"
      target="_blank"
      rel="noopener noreferrer"
      className="text-white text-5xl hover:text-yellow-200 transition-transform duration-300 transform hover:scale-125"
    >
      <FaFacebook />
    </a>
    <a
      href="https://www.instagram.com/inttrvu.ai/?igshid=NTc4MTIwNjQ2YQ"
      target="_blank"
      rel="noopener noreferrer"
      className="text-white text-5xl hover:text-yellow-200 transition-transform duration-300 transform hover:scale-125"
    >
      <FaInstagram />
    </a>
    <a
      href="https://www.youtube.com/@INTTRVUai"
      target="_blank"
      rel="noopener noreferrer"
      className="text-white text-5xl hover:text-yellow-200 transition-transform duration-300 transform hover:scale-125"
    >
      <FaYoutube />
    </a>
    <a
      href="https://www.linkedin.com/in/rohit-mande-15a3a154/"
      target="_blank"
      rel="noopener noreferrer"
      className="text-white text-5xl hover:text-yellow-200 transition-transform duration-300 transform hover:scale-125"
    >
      <FaLinkedin />
    </a>
    <a
      href="https://web.whatsapp.com/"
      target="_blank"
      rel="noopener noreferrer"
      className="text-white text-5xl hover:text-yellow-200 transition-transform duration-300 transform hover:scale-125"
    >
      <FaWhatsapp />
    </a>
  </div>
</div>


      {/* Map Section */}
      <div className="rounded overflow-hidden shadow-lg">
        <iframe
          title="location-map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.341056110335!2d73.77751282472244!3d18.513483747736153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf1b9a03ac2d%3A0x566748ed1ac6dbd7!2sAcharya%20House!5e0!3m2!1sen!2sin!4v1732789913403!5m2!1sen!2sin"
          width="100%"
          height="400"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <Footer />
    </div>
  );
};

export default ContactPage;
