import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import CVMentor from "./CVMentor";

const CVReview = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div>
        <div className="bg-white text-black py-8 px-4 md:px-16">
          {/* Header Section */}
          <Header />

          {/* Navigation Section */}
          <div className="flex justify-left space-x-4 text-gray-600 text-lg font-semibold md:text-base mb-8 mt-12">
            <button
              className="hover:text-black"
              onClick={() => navigate("/mockinterview")}
            >
              Mock Interview 
            </button>

            <button
              className="hover:text-black"
              onClick={() => navigate("/cvreview")}
            >
              CV Review
            </button>

            <button
              className="hover:text-black"
              onClick={() => navigate("/careerguidance")}
            >
              Career Guidance
            </button>

            <button className="hover:text-black">Other</button>
          </div>

          {/* Main Heading */}
          <h1 className="text-xl md:text-4xl font-bold mt-12 md:mt-16 text-center">
            Find a CV review coach to get job offers at Meta, Google, McKinsey,
            etc.
          </h1>

          {/* Subheading */}
          <p className="mt-8 md:mt-10 text-sm md:text-lg max-w-3xl mx-auto text-center">
            Easily schedule a 1-hour call to get feedback on your resume for
            consulting, product, engineering, etc. Improve how you position
            yourself, rewrite parts of your resume, and regain confidence.
          </p>
        </div>
        {/* CV Mentor Section */}
        <div className="mt-12 md:mt-16">
          <CVMentor />
        </div>
      </div>
    </div>
  );
};

export default CVReview;
