import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import DynamicCard from "./DynamicCard";

const MockInterview = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div>
        <div className="bg-white text-black py-8 px-4 md:px-16">
          {/* Header Section */}
          <Header />

          {/* Navigation Section */}
          <div className="flex justify-left space-x-4 text-gray-600 text-lg font-semibold md:text-base mb-8 mt-12">
            <button
              className="hover:text-black"
              onClick={() => navigate("/mockinterview")}
            >
             Mock Interview 
            </button>

            <button
              className="hover:text-black"
              onClick={() => navigate("/cvreview")}
            >
              CV Review
            </button>

            <button
              className="hover:text-black"
              onClick={() => navigate("/careerguidance")}
            >
              Career Guidance
            </button>

            <button className="hover:text-black">Other</button>
          </div>

          {/* Main Heading */}
          <h1 className="text-xl md:text-4xl font-bold mt-12 md:mt-16 text-center">
            Find an interview coach to get job offers at Meta, Google, McKinsey,
            etc.
          </h1>

          {/* Subheading */}
          <p className="mt-8 md:mt-10 text-sm md:text-lg max-w-3xl mx-auto text-center">
            Easily schedule time with an interview coach to prepare for your
            upcoming interviews. Practice real interviews, get expert feedback,
            and gain company-specific insights.
          </p>
        </div>

        {/* Dynamic Card Section */}
        <div className="mt-12 md:mt-16">
          <DynamicCard />
        </div>
      </div>
    </div>
  );
};

export default MockInterview;
