import React from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";

const CompleteBlog = () => {
  const location = useLocation();
  const { img, title } = location.state || {};

  return (
    <div className="bg-slate-200">
      <Header />
      <div className="flex flex-wrap lg:flex-nowrap lg:flex-row p-6 pt-20 gap-16">
        {/* Blog Section */}
        <div className="lg:w-9/12 w-full flex flex-col ml-0 lg:ml-20 mr-0 lg:mr-24 gap-6">
          <div>
            {/* Image */}
            <div className="my-8 max-w-full mx-auto h-96">
              <img
                src={img}
                alt="Blog Image"
                className="h-96 w-full object-cover"
              />
              <h1 className="text-xl font-bold ml-4 text-gray-900">{title}</h1>
            </div>
            <p className="text-black mt-2 ml-4">by writer name | Date</p>
          </div>
          <h1 className="text-3xl lg:text-5xl font-bold ml-4 text-gray-900">
            {title}
          </h1>

          {/* Main Content Container */}
          <div className="container mx-auto px-4 lg:px-0 flex flex-col lg:flex-row lg:space-x-10 text-black ml-4">
            {/* Blog Content Section */}
            <article className="lg:w-3/4 p-4 lg:p-8 mb-8">
              <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">{title}</h2>
                <p>
                  In today’s data-driven world, Data Science and Data Analytics
                  have become two of the most sought-after fields, offering
                  tremendous job opportunities. However, choosing the right path
                  can be confusing. In this article, we’ll explore the key
                  differences between Data Science and Data Analytics, focusing
                  on their scope, goals, job roles, necessary skills and
                  potential salaries. By the end, you’ll have a clearer
                  understanding of which career path might suit you better.
                </p>
              </section>

              <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4 ">
                  What is Data Science?
                </h2>
                <p>
                  Data Science is an interdisciplinary field that uses
                  scientific methods, algorithms and systems to extract
                  knowledge and insights from structured and unstructured data.
                  It focuses on creating predictive models and using machine
                  learning to forecast trends. A data scientist works with large
                  datasets to uncover patterns that inform business strategies
                  and decision-making.
                </p>
              </section>

              <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4 ">
                  What is Data Analytics?
                </h2>
                <p>
                  Data Analytics is the process of analysing raw data to find
                  trends and answer specific questions. It focuses more on
                  interpreting existing data and helping businesses make
                  informed decisions based on those insights. While data
                  scientists predict future trends and focus on interpreting
                  past data.
                </p>
              </section>

              <hr className="m-4" />

              <section className="mb-6">
                <h2 className="text-2xl font-bold mb-4">
                  Scope & Job Roles: Data Science Vs Data Analytics
                </h2>
                <h2 className="text-2xl font-semibold mb-4">
                  Scope of Data Science
                </h2>
                <p>
                  The main differences lie in the approaches and goals. While
                  data science is focused on predictive modeling, data analytics
                  is more about understanding the existing data trends.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-4 ">
                  Job Roles and Average Salaries for Data Analysts in India
                  (Fresher)
                </h2>
                <ul className="list-disc ml-10 mb-4">
                  <li> Data Analyst – ₹4-10 LPA</li>
                  <li> Business Intelligence Analyst – ₹5-12 LPA</li>
                  <li> Operations Analyst – ₹4-9 LPA</li>
                  <li> Financial Analyst – ₹3-8 LPA</li>
                </ul>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-4 ">
                  Job Roles and Average Salaries for Data Analysts in India
                  (Senior)
                </h2>
                <ul className="list-disc ml-10 mb-4">
                  <li> Data Analyst – ₹8-20 LPA</li>
                  <li> Business Intelligence Analyst – ₹10-25 LPA</li>
                  <li> Operations Analyst – ₹7-18 LPA</li>
                  <li> Financial Analyst – 8-18 LPA</li>
                </ul>
              </section>

              <hr />

              <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4 ">
                  Scope of Data Analytics
                </h2>
                <p>
                  Data Analytics is crucial for businesses that need to make
                  data-driven decisions. It is widely used in marketing,
                  operations, finance and sales departments to optimize
                  performance, reduce costs and increase revenue.
                </p>
              </section>
            </article>
          </div>
        </div>

        {/* Sidebar Section */}
        <div className="lg:w-3/12 w-full lg:pl-6 mt-6 lg:mt-0 items-end">
          <div className="bg-white p-4">
            <h3 className="text-xl font-semibold mb-4">Our Services</h3>
            <ul className="space-y-2">
              <li>
                <a href="#" className="text-blue-500 hover:underline">
                  Book A Mock Interview
                </a>
              </li>
              <li>
                <a href="#" className="text-blue-500 hover:underline">
                  Review a CV
                </a>
              </li>
              <li>
                <a href="#" className="text-blue-500 hover:underline">
                  Get Career Guidance
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompleteBlog;
