import React from 'react'
import Body from './components/Body'

const App = () => {

  return (
    <div>
        <Body/>
    </div>
  )
}
export default App

