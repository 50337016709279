import React, { useState, useEffect} from "react";
import Header from "./Header";
import Dummy from "./Dummy";

const FrontPage = () => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const texts = ["Mock Interviews", "CV Review", "Career Guidance"];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-gray-100">
      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <div className="p-6 min-h-screen flex flex-col justify-center items-center">
        <div className="text-center mt-16 md:mt-32 px-4">
          <h1 className="text-3xl md:text-5xl font-bold text-black leading-tight">
          Find the Right Mentor for Your Data Science Journey
          </h1>
          <div className="flex justify-center items-center mt-4 md:mt-8">
            <div className="relative h-20 md:h-24 w-full max-w-xl flex justify-center items-center overflow-hidden">
              {texts.map((text, index) => (
                <span
                  key={index}
                  className={`absolute transition-all duration-700 ease-in-out ${
                    index === currentTextIndex
                      ? "translate-y-0 opacity-100"
                      : "translate-y-20 opacity-0"
                  } text-3xl md:text-5xl font-bold text-black`}
                >
                  {text}
                </span>
              ))}
            </div>
          </div>
        </div>

        {/* Steps Section (Dummy Component) */}
        <div className="w-full mt-2">
          <Dummy />
        </div>

        {/* Call-to-Action Section */}
        <div className="text-center mt-10">
          <p className="text-xl sm:text-2xl font-semibold text-black">
            Step Closer to Get Hired!
          </p>
        </div>

        {/* Description Section */}
        <div className="mt-10 text-center text-black px-4">
          <h3 className="text-3xl md:text-5xl font-bold">What We Do</h3>
          <p className="mt-6 max-w-3xl text-base md:text-xl font-semibold mx-auto">
          We are here to make your Data Science journey easier and help you land your dream job! Our platform offers personalized support, including mock interviews, CV reviews and career guidance —all from industry experts who know what it takes to succeed. 
          </p>
         
        </div>
      </div>
    </div>
  );
};

export default FrontPage;
