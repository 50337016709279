import React, { useEffect, useRef } from "react";

const Dummy = () => {
  const lineRef = useRef(null);

  useEffect(() => {
    const lineElement = lineRef.current;
    if (lineElement) {
      // Start the line animation on component mount
      lineElement.classList.add("grow-line");
    }
  }, []);

  return (
    <div className="mt-8">
        <div className="relative mt-10 px-4 md:px-8 lg:px-16">
      {/* Steps Section */}
      <div className="flex justify-center items-center space-x-2 lg:space-x-4 relative">
        {[{ icon: "👩‍🏫", text: "Choose Mentor" },
          { icon: "📅", text: "Schedule Slot" },
          { icon: "🪪", text: "Make Payment" },
          { icon: "✅", text: "Activate Service" },
          { icon: "🤝", text: "Get Hired" }].map((step, index) => (
          <div key={index} className="text-center relative z-10">
            <div 
              className="bg-black text-white w-16 h-16 flex justify-center items-center rounded-full text-2xl"
              style={{
                animation: `zoom 0.6s ease-in-out ${index * 600}ms forwards`,
              }}
            >
              {step.icon}
            </div>
            <p className="mt-3 text-black">{step.text}</p>
          </div>
        ))}
        
        {/* Animated Line */}
        <div
          className="absolute top-10 transform -translate-y-1/2 h-1 bg-blue-600"
          ref={lineRef}
          style={{
            left: 0,
            width: 0, 
            position: "absolute",
            marginLeft: "30%",
          
          }}
        ></div>
      </div>
    </div>
    </div>
  );
};

export default Dummy;
