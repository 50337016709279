import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import CareerGuidanceMentor from './CareerGuidanceMentor';

const CareerGuidance = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div>
    <div className="bg-white text-black py-8 px-4 md:px-16">
      <Header />

      {/* Navigation Section */}
     <div className="flex justify-left space-x-4 text-gray-600 text-lg font-semibold md:text-base mb-8 mt-12">
            <button
              className="hover:text-black"
              onClick={() => navigate("/mockinterview")}
            >
              Mock Interview 
            </button>

            <button
              className="hover:text-black"
              onClick={() => navigate("/cvreview")}
            >
              CV Review
            </button>

            <button
              className="hover:text-black"
              onClick={() => navigate("/careerguidance")}
            >
              Career Guidance
            </button>

            <button className="hover:text-black">Other</button>
          </div>

      {/* Main Heading */}
      <h1 className="text-2xl md:text-4xl font-bold mt-16 text-center">
        Find a career coach to get clarity and confidence about your next career transition.
      </h1>

      {/* Subheading */}
      <p className="mt-10 text-lg md:text-lg max-w-3xl mx-auto text-center">
        Easily schedule time with a career coach. Discuss what roles and companies to target in Tech, Consulting, Finance, etc. Identify the skills you’re missing and how to gain them. Build a plan of attack.
      </p>
      </div>
      {/* CareerGuidanceMentor Section */}
      <div className="mt-12 md:mt-16">
        <CareerGuidanceMentor />
      </div>
    </div>
    </div>
  );
};

export default CareerGuidance;
