import React from "react";
import {  FaFacebook,  FaInstagram,  FaWhatsapp,  FaYoutube,  FaLinkedin,} from "react-icons/fa";
import logo_img from "../assets/logo.png";
import { useNavigate } from "react-router-dom";


const Footer = () => {
  const navigate = useNavigate();
  

  return (
    <footer className="bg-black py-12 cursor-pointer">
      <div className="container mx-auto px-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
        {/* Logo Section */}
        <div className="flex flex-col items-center md:items-start space-y-4">
          {/* Logo */}
          <div className="flex items-center space-x-2">
            <div className="w-12 h-12 hover:scale-110 transition-transform duration-300">
              <img className="w-full h-full" src={logo_img} alt="Logo" />
            </div>
          </div>

          {/* Social Media Icons */}
          <div className="flex space-x-4 mt-4">
            <a
              href="https://www.facebook.com/people/inttrvuai/100092270186906/?mibextid=LQQJ4d"
              target="_blank"
              rel="noopener noreferrer"
              className="text-2xl text-white hover:text-yellow-200 transition-transform duration-300 transform hover:scale-125"
            >
              <FaFacebook />
            </a>
            <a
              href="https://www.instagram.com/inttrvu.ai/?igshid=NTc4MTIwNjQ2YQ"
              target="_blank"
              rel="noopener noreferrer"
              className="text-2xl text-white hover:text-yellow-200 transition-transform duration-300 transform hover:scale-125"
            >
              <FaInstagram />
            </a>
            <a
              href="https://whatsapp.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-2xl text-white hover:text-yellow-200 transition-transform duration-300 transform hover:scale-125"
            >
              <FaWhatsapp />
            </a>
            <a
              href="https://www.youtube.com/@INTTRVUai/videos"
              target="_blank"
              rel="noopener noreferrer"
              className="text-2xl text-white hover:text-yellow-200 transition-transform duration-300 transform hover:scale-125"
            >
              <FaYoutube />
            </a>
            <a
              href="https://www.linkedin.com/in/rohit-mande-15a3a154/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-2xl text-white hover:text-yellow-200 transition-transform duration-300 transform hover:scale-125"
            >
              <FaLinkedin />
            </a>
          </div>
        </div>

        {/* Services Section */}
        <div className="text-center md:text-left text-white">
          <h2 className="text-lg font-bold mb-4">Our Services</h2>
          <ul className="space-y-2">
            <li>
              <span
                className="hover:text-yellow-200 transition-colors duration-200"
                onClick={() => navigate("/mockinterview")}
              >
                Mock Interviews
              </span>
            </li>
            <li>
              <span
                className="hover:text-yellow-200 transition-colors duration-200"
                onClick={() => navigate("/mockinterview")}
              >
                CV Review
              </span>
            </li>
            <li>
              <span
                className="hover:text-yellow-200 transition-colors duration-200"
                onClick={() => navigate("/mockinterview")}
              >
                Career Guidance
              </span>
            </li>
          </ul>
        </div>

        {/* Useful Links Section */}
        <div className="text-center md:text-left text-white">
          <h2 className="text-lg font-bold mb-4">Useful Links</h2>
          <ul className="space-y-2">
            <li>
              <span
                className="hover:text-yellow-200 transition-colors duration-200"
                onClick={() => navigate("/")}
              >
                Home
              </span>
            </li>

            <li>
              <span
                className="hover:text-yellow-200 transition-colors duration-200"
                onClick={() => navigate("/blogs")}
              >
                Blog
              </span>
            </li>


            <li>
              <span
                className="hover:text-yellow-200 transition-colors duration-200"
                onClick={() => navigate("/content")}
              >
                Content
              </span>
            </li>
            <li>
              <span
                className="hover:text-yellow-200 transition-colors duration-200"
                onClick={() => navigate("/about")}
              >
                About Us
              </span>
            </li>
                  
            <li>
              <span
                className="hover:text-yellow-200 transition-colors duration-200"
                onClick={() => navigate("/privacy-refund-policy")}
              >
                Privacy & Refund Policy
              </span>
            </li>
            <li>
              <span
                className="hover:text-yellow-200 transition-colors duration-200"
                onClick={() => navigate("/termsandconditions")}
              >
                Terms and Conditions
              </span>
            </li>
          </ul>
        </div>

        {/* Contact Section */}
        <div className="text-center md:text-left text-white">
          <h2 className="text-lg font-bold mb-4">Contact Us</h2>
          <p className="mb-2">
            <span className="font-semibold">Address: </span>
            Office No. 307 , Acharya House, Plot No.24 Sr.No.19/12/1, Bavdhan, Pune, Maharashtra-411021
          </p>
          <p className="mb-2">
            <i className="fas fa-phone"></i> 123-456-789
          </p>
          <p>
            <i className="fas fa-envelope"></i> abc@gmail.com
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;










