import React from "react";

const ScheduledItem = ({ date, timeSlot, service, price }) => {
  return (
    <div className="p-4 mb-4 bg-gray-100 rounded-lg flex justify-between items-center">
      <div>
        <div>
          <h2>{date} </h2>
          <h2> {timeSlot}</h2>
        </div>
        <p>{service}</p>
      </div>
      <p className="text-lg font-bold">₹{price}</p>
    </div>
  );
};

export default ScheduledItem;
