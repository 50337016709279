import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const TermsAndConditions = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />

      <div className="flex-grow bg-white py-28 px-4 md:px-16 lg:px-32">
        {/* Heading */}
        <h1 className="text-2xl md:text-3xl font-bold text-black mb-10 text-center">
          Terms and Conditions
        </h1>

        {/* Content */}
        <div className="text-black text-base md:text-lg leading-relaxed space-y-8">
         <p>The term ‘<span className="font-semibold">INTTRVU</span>’ or ‘<span className="font-semibold">us</span>’ or ‘<span className="font-semibold">we</span>’ refers to'<span className="font-semibold">
              INTTRVU LEARNING PLATFORM LLP</span>'{" "}
             whose company registration number{" "}
            <span className="font-semibold">ACB-9489</span>.
            The term ‘<span className="font-semibold">you</span>’ refers to the user/ viewer/ subscriber/student of training programs.
           </p>

          {/* Content Ownership and Copyright*/}
        <div>
            <h2 className="text-lg md:text-xl font-bold mb-4">Content Ownership and Copyright</h2>
            <div className="space-y-6 pl-6">
              <div>
                <h3 className="font-semibold">1. Copyright Restrictions</h3>
                <ul className="list-disc pl-10">
                  <li>
                    Copying, distributing, reproducing, or modifying INTTRVU content without permission is prohibited. Violations will be subject to legal action under applicable copyright laws.
                  </li>
               </ul>
              </div>

              <div>
                <h3 className="font-semibold">2. Third-Party Services</h3>
                <ul className="list-disc pl-10">
                  <li>
                    All materials on this website, including the design, layout,
                    look, appearance, and graphics, are owned by or licensed to
                    INTTRVU. Reproduction is prohibited except in accordance
                    with the copyright notice.
                  </li>
                  <li>
                   INTTRVU may share your data with third-party service providers to facilitate services. We assume no responsibility for the privacy practices or content of third-party websites.
                  </li>
                </ul>
              </div>
            </div>
         </div>      
         
          {/* Service Modifications */}
          <div>
            <h2 className="text-lg md:text-xl font-bold mb-4">
              Service Modifications
            </h2>
            <div className="space-y-6 pl-6">
              <div>
                <ul className="list-disc pl-10">
                  <li>
                    1. INTTRVU reserves the right to add, change, suspend, or
                    discontinue services at its sole discretion.
                  </li>
                  <li>
                    2. Continued use of INTTRVU services after changes
                    constitutes acceptance of updated terms.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Disclaimer */}
          <div>
            <h2 className="text-lg md:text-xl font-bold mb-4">Disclaimer</h2>
            <div className="space-y-6 pl-6">
              <div>
                <h3 className="font-semibold">1. No Job Guarantee</h3>
                <ul className=" pl-6">
                  <li>
                    We do not provide a job guarantee. Career outcomes depend on
                    individual effort and market conditions.
                  </li>
                </ul>
              </div>
            </div>
        </div>


        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
