import React from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Blog from "./Blog";
import MentorCard from "./MentorCard";
import Form2 from "./Form2";
import CompleteBlog from "./CompleteBlog";
import CVReview from "./CVReview";
import MockInterview from "./MockInterview";
import CareerGuidance from "./CareerGuidance";
import Content from "./Content";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";
import ContactPage from "./ContactPage";
import ScrollToTop from "./ScrollToTop"; // Import ScrollToTop

// Layout Component to wrap routes
const Layout = () => (
  <>
    <ScrollToTop /> {/* Place inside Router context */}
    <Outlet /> {/* Renders the matched child route */}
  </>
);

const Body = () => {
  const appRouter = createBrowserRouter([
    {
      element: <Layout />, // Wrap all routes with Layout
      children: [
        { path: "/", element: <Home /> },
        { path: "/about", element: <About /> },
        { path: "/contact", element: <ContactPage /> },
        { path: "/blogs", element: <Blog /> },
        { path: "/content", element: <Content /> },
        { path: "/form2/:id", element: <Form2 /> },
        { path: "/completeblog/:title", element: <CompleteBlog /> },
        { path: "/mentor/:id", element: <MentorCard /> },
        { path: "/cvreview", element: <CVReview /> },
        { path: "/careerguidance", element: <CareerGuidance /> },
        { path: "/mockinterview", element: <MockInterview /> },
        { path: "/privacy-refund-policy", element: <PrivacyPolicy /> },
        { path: "/termsandconditions", element: <TermsAndConditions /> },
      ],
    },
  ]);

  return <RouterProvider router={appRouter} />;
};

export default Body;
