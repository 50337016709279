import React from "react";

const MentorBenefits = () => {
  const benefits = [
    {
      title: "Tailored Guidance",
      description:
        "Get personalized career advice, mock interviews, and CV reviews from real industry professionals who understand your field.",
    },
    {
      title: "Hassle-Free Booking",
      description:
        "Schedule sessions with your choosen mentor in just a few clicks—flexible and convenient to fit your busy schedule.",
    },
    {
      title: "Transformative Outcomes",
      description:
        "Expert mentorship can turn confusion into clarity and help you achieve your career aspirations faster.",
    },
    {
      title: "Affordable Excellence",
      description:
        "Access top-notch mentoring at competitive rates—quality guidance without breaking the bank.",
    },
  ];

  return (
    <div className="max-w-7xl mx-auto px-6 py-12">
      <h1 className="text-2xl md:text-3xl font-semibold text-center mb-6">
        Stop guessing your next move
      </h1>
      <p className="text-lg text-center font-semibold mb-12">
        Connect with a mentor to gain clarity and accelerate your career growth.
      </p>
      <div className="flex justify-center">
      <div className="space-y-8">
        {benefits.map((benefit, index) => (
          <div key={index} className="flex items-start space-x-4">
            <div className="flex-shrink-0">
              <div className="w-8 h-8 bg-green-600 rounded-full flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-5 h-5 text-black"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
            </div>
            <div>
              <h2 className="text-xl font-semibold">{benefit.title}</h2>
              <p className="text-black font-medium">{benefit.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default MentorBenefits;
