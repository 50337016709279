import React, { useState, useRef, useEffect } from "react";
import ScheduledItem from "./ScheduledItems";

const RegistrationForm2 = ({ details }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    contactNo: "",
    verification: "",
  });

  const availableTechnologies = [
    "Data Science",
    "Python",
    "Data Analyst",
    "Tableau",
    "Power BI",
  ];
  const maxSelection = 3;
  const [selectedTechnologies, setSelectedTechnologies] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleTechnologySelect = (tech) => {
    if (selectedTechnologies.includes(tech)) {
      setSelectedTechnologies(selectedTechnologies.filter((t) => t !== tech));
    } else if (selectedTechnologies.length < maxSelection) {
      setSelectedTechnologies([...selectedTechnologies, tech]);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    //validation for contact number
    if (name === "contactNo" && !/^\d*$/.test(value)) {
      return;
    }

    setFormData({ ...formData, [name]: value });
  };

  // Email verification logic
  const handleVerifyEmail = () => {
    alert(`Verifying email: ${formData.email}`);
  };

  // Form submission logic
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default submission behavior

    // Additional validation for contact number
    if (!/^\d{10}$/.test(formData.contactNo)) {
      alert("Contact number must be exactly 10 digits.");
      return;
    }

    // Validate technology selection
    if (selectedTechnologies.length === 0) {
      setError("Please select at least one skill.");
      return;
    }

    setError(""); // Clear the error if validation passes

    const completeData = {
      ...formData,
      selectedTechnologies,
      date: details.date,
      timeSlot: details.timeSlot,
    };
    alert("Form Submitted");
    console.log(completeData);
  };







//  Razor pay

const loadRazorpay = () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    script.onload = () => initializeRazorpay();
    document.body.appendChild(script);
  };

  const initializeRazorpay = () => {
    const options = {
      key: "rzp_live_e0Z8MoR3S0pzZT", // Replace with your Razorpay Key ID
      amount: 100, // Amount in paise
      currency: "INR",
      name: "intraprep", // Business name
      description: "Test Transaction",
      image: "https://example.com/your_logo", // Optional: Add your logo URL
      order_id: "order_PR4uAIirzTPaef", // Replace with the order ID from your backend
      handler: function (response) {
        alert(`Payment ID: ${response.razorpay_payment_id}`);
        alert(`Order ID: ${response.razorpay_order_id}`);
        alert(`Signature: ${response.razorpay_signature}`);
      },
      prefill: {
        name: formData.fullName || "", // Dynamically set from formData
        email: formData.email || "",  // Dynamically set from formData
        contact: formData.contactNo || "", // Dynamically set from formData
        
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
  
    const rzp = new window.Razorpay(options);
    rzp.on("payment.failed", function (response) {
      alert(`Error Code: ${response.error.code}`);
      alert(`Description: ${response.error.description}`);
      alert(`Source: ${response.error.source}`);
      alert(`Step: ${response.error.step}`);
      alert(`Reason: ${response.error.reason}`);
      alert(`Order ID: ${response.error.metadata.order_id}`);
      alert(`Payment ID: ${response.error.metadata.payment_id}`);
    });
    rzp.open();
  };

  const handlePayment = (e) => {
    e.preventDefault();
    if (!formData.fullName || !formData.email || !formData.contactNo) {
      alert("Please fill out all required fields before proceeding.");
      return;
    }  
    // Proceed to load Razorpay
    loadRazorpay();
  };
  


 console.log(formData.fullName);

  return (
    <div className="max-w-lg mx-auto p-6 w-full bg-white">
      {/* Render ScheduleItem */}
      <ScheduledItem
        date={details.date}
        timeSlot={details.timeSlot}
        price={details.price}
      />

      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Other form fields */}
        <div>
          <label className="block text-m font-semibold text-black">
            Full Name<span className="text-red-500"> *</span>
          </label>
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleInputChange}
            className="mt-1 block w-full p-2 border border-gray-400 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>

        <div className="relative">
          <label className="block text-m font-semibold text-black">
            E-Mail Id<span className="text-red-500"> *</span>
          </label>
          <div className="flex items-center mt-1 relative">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="block w-full p-2 pr-20 border border-gray-400 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              required
            />
            <button
              type="button"
              className="absolute right-0 top-0 h-full px-5 bg-blue-600 text-white text-sm font-semibold rounded-r-md hover:bg-blue-800"
              onClick={handleVerifyEmail}
            >
              Verify
            </button>
          </div>
        </div>

        <div>
          <label className="block text-m font-semibold text-black">
            Verification<span className="text-red-500"> *</span>
          </label>
          <input
            type="text"
            name="verification"
            placeholder="Enter OTP"
            value={formData.verification}
            onChange={handleInputChange}
            className="mt-1 block w-full p-2 border border-gray-400 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>

        <div>
          <label className="block text-m font-semibold text-black">
            Contact No<span className="text-red-500"> *</span>
          </label>
          <input
            type="tel"
            name="contactNo"
            value={formData.contactNo}
            onChange={handleInputChange}
            pattern="\d{10}" // HTML pattern for exactly 10 digits
            className="mt-1 block w-full p-2 border border-gray-400 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            required
          />

          {/* Error Message */}
          {!/^\d{10}$/.test(formData.contactNo) &&
            formData.contactNo !== "" && (
              <p className="text-red-500 text-sm">
                Contact number must be exactly 10 digits.
              </p>
            )}
        </div>

        {/* Technology Selection */}
        <div className="w-full" ref={dropdownRef}>
          <label className="block mb-2 font-semibold text-black">
            Skills <span className="text-red-500"> *</span>(Max select 3 Skills)
          </label>
          <div
            className="w-full px-3 py-2 border border-gray-300 rounded cursor-pointer"
            onClick={toggleDropdown}
          >
            <div className="flex flex-wrap gap-2">
              {selectedTechnologies.map((tech) => (
                <div
                  key={tech}
                  className="flex items-center bg-blue-100 text-blue-800 px-2 py-1 rounded-md"
                >
                  <span>{tech}</span>
                  <button
                    className="ml-1 text-black"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleTechnologySelect(tech);
                    }}
                  >
                    &times;
                  </button>
                </div>
              ))}

              {selectedTechnologies.length === 0 && (
                <span className="text-gray-500">Select technologies...</span>
              )}
            </div>
          </div>

          {isOpen && (
            <ul className="absolute mt-1 w-72 bg-white border border-gray-300 rounded shadow-lg max-h-40 overflow-y-auto z-10">
              {availableTechnologies.map((tech) => (
                <li
                  key={tech}
                  className="px-3 py-2 flex items-center justify-between hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleTechnologySelect(tech)}
                >
                  <span>{tech}</span>
                  <span
                    className={`ml-2 ${
                      selectedTechnologies.includes(tech)
                        ? "text-green-500"
                        : "text-transparent group-hover:text-black"
                    }`}
                  >
                    ✔
                  </span>
                </li>
              ))}
            </ul>
          )}
        </div>
        {error && <p className="text-red-500 text-sm">{error}</p>}

        {/* Proceed Button */}
        <button
  id="rzp-button1"
  type="button" // Use type "button" to avoid triggering form submission
  className="w-64 py-3 px-4 bg-blue-600 text-white font-bold rounded-md shadow-sm hover:bg-blue-700"
  onClick={(e) => {
    handlePayment(e);
  }}
>
  Pay
</button>
      </form>
    </div>
  );
};

export default RegistrationForm2;
