import React from "react";
import vision_img from "../assets/vision.jpg"; 

const VisionSection = () => {
  return (
    <div className="relative h-[40rem] md:h-96 ">
      {/* Background Image */}
      <img 
        className="w-full h-full object-cover" 
        src={vision_img} 
        alt="Background" 
      />

      {/* Content */}
      <div className="absolute inset-0 flex items-center px-4 md:px-16 text-white">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 w-full">
          {/* Left Section (Vision Content) */}
          <div className="flex flex-col justify-center space-y-4 px-4 md:px-0">
            <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center lg:text-left">
              Vision
            </h2>
            <p className="text-base md:text-lg lg:text-xl leading-relaxed text-center lg:text-left">
              At INTRVU, we envision a world where talent meets opportunity seamlessly.
              By empowering learners to confidently navigate the job market, we aim to
              transform not only individual careers but also the future of the data
              science and AI industry.
            </p>
          </div>

          {/* Right Section (Features) */}
          <div className="flex flex-col justify-center space-y-6 px-4 md:px-0">
            {/* Feature 1 */}
            <div className="flex items-start space-x-4">
              <div className="w-10 h-10 md:w-12 md:h-12 bg-blue-500 rounded-full flex items-center justify-center">
                <span className="text-white text-xl md:text-2xl font-bold">📄</span>
              </div>
              <div>
                <h3 className="text-lg md:text-xl font-semibold">Resume Building:</h3>
                <p className="text-sm md:text-base">
                  Crafting professional resumes that highlight your skills,
                  experience, and achievements to impress potential employers.
                </p>
              </div>
            </div>

            {/* Feature 2 */}
            <div className="flex items-start space-x-4">
              <div className="w-10 h-10 md:w-12 md:h-12 bg-blue-500 rounded-full flex items-center justify-center">
                <span className="text-white text-xl md:text-2xl font-bold">👤</span>
              </div>
              <div>
                <h3 className="text-lg md:text-xl font-semibold">CV Preparation:</h3>
                <p className="text-sm md:text-base">
                  Creating standout CVs tailored to your target roles,
                  emphasizing key qualifications and accomplishments.
                </p>
              </div>
            </div>

            {/* Feature 3 */}
            <div className="flex items-start space-x-4">
              <div className="w-10 h-10 md:w-12 md:h-12 bg-blue-500 rounded-full flex items-center justify-center">
                <span className="text-white text-xl md:text-2xl font-bold">💼</span>
              </div>
              <div>
                <h3 className="text-lg md:text-xl font-semibold">Career Guidance:</h3>
                <p className="text-sm md:text-base">
                  Personalized sessions to help you navigate the job market,
                  identify strengths, and ace interviews with confidence.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisionSection;
