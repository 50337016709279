import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CVReview_img } from "../utils/constants";
import Mock_img from "../assets/mock.jpg";
import CareerGuidance_img from "../assets/CareerGuidance.jpg";

const OverlappingCards = () => {
  const [hoverCardOne, setHoverCardOne] = useState(false);
  const [hoverCardThree, setHoverCardThree] = useState(false);
  const [hoverCardFive, setHoverCardFive] = useState(false);

  const navigate = useNavigate();

  return (
    <div>
      <h2 className="text-center text-3xl font-bold mt-10">Our Services</h2>
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-12 px-8 mt-20 mb-20">
        {/* Wrapper for Card 1 and Card 2 */}
        <div className="bg-white">
          <div
            className="relative w-full h-[500px] overflow-hidden"
            onMouseEnter={() => setHoverCardOne(true)}
            onMouseLeave={() => setHoverCardOne(false)}
          >
            {/* Card 1 */}
            <div className="absolute w-full h-full bg-black text-white transition-colors duration-300 hover:bg-gray-900">
              <div className="text-center">
                <div className="flex justify-center bg-white">
                  <img
                    className="w-full h-52  border  border-gray-300 rounded-t-lg"
                    src={Mock_img}
                    alt="not found"
                  />
                </div>
                <div className="p-8">
                  <h3 className="font-bold text-xl mb-2">Mock Interview</h3>
                  <ul>
                    <li>
                      Master your confidence and ace your next interview! Gain
                      expert feedback, overcome common pitfalls, and prepare
                      like a pro to land your dream job.
                    </li>
                  </ul>
                  <div className="bg-blue-600 p-3 mt-4 rounded-lg items-center">
                    <button className="text-white font-semibold">
                      Know more
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Card 2 - Overlaps Card 1 when hovered */}
            <div
              className={`absolute w-full h-full bg-black text-white p-6 rounded-t-lg transition-transform duration-500 ease-out ${
                hoverCardOne
                  ? "translate-y-0 opacity-100 z-10"
                  : "translate-y-full opacity-0 z-0"
              }`}
            >
              <div className="text-center">
                <h2 className="text-2xl font-bold mb-4">
                  Why to take Mock Interview?
                </h2>

                <div className="max-w-3xl mx-auto">
                  <ul className="list-disc text-left pl-5 space-y-4 text-white">
                    <li className="font-semibold text-lg">
                      Real-World Perspective{" "}
                    </li>

                    <li className="font-semibold text-lg ">
                      Industry-specific questions
                    </li>

                    <li className="font-semibold text-lg ">
                      Feedback and Improvement
                    </li>

                    <li className="font-semibold text-lg ">
                      Understanding Evaluation Criteria{" "}
                    </li>

                    <li className="font-semibold text-lg">Boost Confidence</li>

                    <li className="font-semibold text-lg ">
                      Skill Enhancement
                    </li>
                  </ul>
                </div>

                <div
                  className="bg-blue-600 p-3 mt-2 rounded-lg items-center"
                  onClick={() => navigate("/mockinterview")}
                >
                  <button className="text-white font-semibold">
                    Book a Mock
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Wrapper for Card 3 and Card 4 */}
        <div className="bg-white rounded-b-lg">
          <div
            className="relative w-full h-[500px] overflow-hidden"
            onMouseEnter={() => setHoverCardThree(true)}
            onMouseLeave={() => setHoverCardThree(false)}
          >
            {/* Card 3 */}
            <div className="absolute w-full h-full bg-black text-white transition-colors duration-300 hover:bg-gray-900">
              <div className="text-center">
                <div className="flex justify-center bg-white">
                  <img
                    className="w-full h-52  border  border-gray-300 rounded-t-lg"
                    src={CVReview_img}
                    alt="not found"
                  />
                </div>
                <div className="p-4">
                  <h3 className="font-bold text-xl mb-2">CV Reviews</h3>
                  <ul>
                    <li>
                      Your CV is your first impression—make it unforgettable.
                      Let our experts help you stand out in a competitive job
                      market.
                    </li>
                    <li>
                      Get more interview calls with a CV that highlights your
                      true potential.
                    </li>
                  </ul>
                  <div className="bg-blue-600 p-3 mt-2 rounded-lg items-center">
                    <button className="text-white font-semibold">
                      Know more
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Card 4 - Overlaps Card 3 when hovered */}
            <div
              className={`absolute w-full h-full rounded-lg rounded-t-lg bg-black text-white p-8  transition-transform duration-500 ease-out ${
                hoverCardThree
                  ? "translate-y-0 opacity-100 z-10"
                  : "translate-y-full opacity-0 z-0"
              }`}
            >
              <div className="text-center">
                <h2 className="text-2xl font-bold mb-2 -mt-2">
                  Why to do CV Review?
                </h2>
                <div className="max-w-3xl mx-auto">
                  <ul className="list-disc text-left pl-5 space-y-4 text-white">
                    <li className="font-semibold text-lg">
                    ATS Compatibility
                    </li>

                    <li className="font-semibold text-lg ">
                    Real-World Insight
                    </li>

                    <li className="font-semibold text-lg ">
                    Constructive Feedback
                    </li>

                    <li className="font-semibold text-lg ">
                    Avoid Common Pitfalls</li>

                    <li className="font-semibold text-lg">Tailored to the Role</li>

                    <li className="font-semibold text-lg ">
                    Stand Out from the Crowd
                    </li>
                  </ul>
                </div>
                <div
                  className="bg-blue-600 p-3 mt-1 rounded-lg items-center"
                  onClick={() => navigate("/cvreview")}
                >
                  <button className="text-white text-center font-semibold">
                    Book a CV Review
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Wrapper for Card 5 and Card 6 */}
        <div className="bg-white">
          <div
            className="relative w-full h-[500px] overflow-hidden"
            onMouseEnter={() => setHoverCardFive(true)}
            onMouseLeave={() => setHoverCardFive(false)}
          >
            {/* Card 5 */}
            <div className="absolute w-full h-full bg-black text-white transition-colors duration-300 hover:bg-gray-900">
              <div className="text-center">
                <div className="flex justify-center bg-white">
                  <img
                    className="w-full h-52  border  border-gray-300 rounded-t-lg"
                    src={CareerGuidance_img}
                    alt="not found"
                  />
                </div>
                <div className="p-8">
                  <h3 className="font-bold text-xl mb-2">Career Guidance</h3>
                  <ul>
                    <li>
                      Navigate your career path with clarity and purpose!
                      Receive personalized advice, actionable steps, and expert
                      insights to achieve your goals faster.
                    </li>
                  </ul>
                  <div className="bg-blue-600 p-3 mt-2 rounded-lg items-center">
                    <button className="text-white font-semibold">
                      Know more
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Card 6 - Overlaps Card 5 when hovered */}
            <div
              className={`absolute w-full h-full bg-black rounded-t-lg text-white p-8 transition-transform duration-500 ease-out ${
                hoverCardFive
                  ? "translate-y-0 opacity-100 z-10"
                  : "translate-y-full opacity-0 z-0"
              }`}
            >
              <div className="text-center">
                <div className="text-center">
                  <h2 className="text-2xl font-bold mb-4">
                    Why to take Career Guidance ?
                  </h2>
                  <div className="max-w-3xl mx-auto">
                  <ul className="list-disc text-left pl-5 space-y-4 text-white">
                    <li className="font-semibold text-lg">
                    Clarity on Career Goals
                    </li>

                    <li className="font-semibold text-lg ">
                    Customized Guidance
                    </li>

                    <li className="font-semibold text-lg ">
                    Strategic Career Moves
                    </li>

                    <li className="font-semibold text-lg ">
                    Growth Opportunities</li>

                    <li className="font-semibold text-lg">Tailored to the Role</li>

                    <li className="font-semibold text-lg ">
                    Actionable recommendations
                    </li>
                  </ul>
                </div>
                  <div
                    className="bg-blue-600 p-3 mt-2 rounded-lg items-center"
                    onClick={() => navigate("/careerguidance")}
                  >
                    <button className="text-white font-semibold">
                      Book a Career Gaidance
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverlappingCards;
