import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import backarrow_img from "../assets/backarrow.png";
import RegistrationForm2 from "./RegistrationForm2";
import { useLocation } from "react-router-dom";

const DateCalendar = ({ onShowCard }) => {
  const location = useLocation();
  const { fees } = location.state || {};

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [showRegistration, setShowRegistration] = useState(false);
  const [registrationDetails, setRegistrationDetails] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const timeSlots = [
    "11:00 AM - 11:45 AM",
    "1:00 PM - 1:45 PM",
    "6:00 PM - 6:45 PM",
    "7:00 PM - 7:45 PM",
  ];

  const minDate = new Date();
  const maxDate = new Date("2024-12-10");

  const highlightRangeDates = [];
  let currentDate = new Date(minDate);

  while (currentDate <= maxDate) {
    highlightRangeDates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  const excludedDates = [new Date("2024-12-1"), new Date("2024-12-3")];

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSlotSelect = (index) => {
    setSelectedSlot(index);
    setIsDropdownOpen(false);
  };

  const handleProceed = () => {
    const selectedDateStr = selectedDate
      ? selectedDate.toLocaleDateString("en-GB", {
          weekday: "short",
          day: "numeric",
          month: "short",
          year: "numeric",
        })
      : "";
    const selectedTimeSlot =
      selectedSlot !== null ? timeSlots[selectedSlot] : "";

    setRegistrationDetails({
      date: selectedDateStr,
      timeSlot: selectedTimeSlot,
      price: fees,
    });

    setShowRegistration(true);
  };

  const handleChooseAnotherMentor = () => {
    onShowCard();
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {showRegistration && registrationDetails ? (
        <RegistrationForm2 details={registrationDetails} />
      ) : (
        <div className="p-5 text-black font-medium">
          {/* Header */}
          <h2 className="text-lg text-left w-full mb-3">
            Select a Date<span className="text-red-500"> *</span>
          </h2>

          {/* Date Picker */}
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="dd / MM / yyyy"
            minDate={minDate}
            maxDate={maxDate}
            highlightDates={highlightRangeDates}
            excludeDates={excludedDates}
            placeholderText="Select a date"
            className="w-full sm:w-64 p-3 bg-gray-200 rounded-lg border border-gray-300 text-center font-medium focus:outline-none tracking-wide"
          />

          {selectedDate && (
            <>
              {/* Time Slot Selector */}
              <h2 className="text-lg font-semibold mt-5 mb-2">
                Select time-slot<span className="text-red-500"> *</span>
              </h2>
              <div ref={dropdownRef} className="relative w-full sm:w-64">
                <button
                  onClick={toggleDropdown}
                  className="w-full p-3 bg-gray-200 text-center rounded-lg border border-gray-300"
                >
                  {selectedSlot !== null
                    ? timeSlots[selectedSlot]
                    : "Select time slot"}
                </button>
                {isDropdownOpen && (
                  <ul className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-lg mt-1 z-10">
                    {timeSlots.map((slot, index) => (
                      <li
                        key={index}
                        onClick={() => handleSlotSelect(index)}
                        className="p-3 hover:bg-blue-500 hover:text-white cursor-pointer"
                      >
                        {slot}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </>
          )}

          {/* Proceed Button */}
          <div className="text-left mt-6">
            <button
              type="button"
              className="w-full sm:w-64 py-3 px-4 bg-blue-600 text-white font-bold rounded-md shadow-sm hover:bg-blue-700"
              onClick={handleProceed}
              disabled={!selectedDate || selectedSlot === null}
            >
              Proceed
            </button>

            {/* Choose Another Mentor */}
            <div className="mt-6">
              <button
                className="w-full sm:w-auto bg-white p-4 flex items-center border border-gray-300 rounded-md hover:bg-gray-100"
                onClick={handleChooseAnotherMentor}
              >
                Choose another Mentor
                <img
                  className="ml-2 h-6 w-6"
                  src={backarrow_img}
                  alt="Back arrow"
                />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DateCalendar;
