import React, { useState } from "react";
import blog1_img from "../assets/blog1.jpg";
import blog2_img from "../assets/blog2.png";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";


const paragraphStyles = {
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  display: "-webkit-box",
};

const BlogComponent=() =>{
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleShowForm = (img, title) => {
    navigate("/completeblog/title", {
      state: { img, title },
    });
  };

  return ( 
    <div>
      <Header/>
    <div className="flex flex-wrap lg:flex-nowrap p-6 mt-16">
      {/* Blog Section */}
      <div className="lg:w-2/3 w-full flex flex-col lg:flex-row gap-6 ">

        {/* Blog 1 */}
        <div className="bg-white shadow-md rounded-lg p-4 flex-1">
          
            <img
              src={blog1_img} 
              alt="not found"
              className="w-full h-64 object-cover rounded-md"
            />
    
          {/* Title */}
          <h2 className="text-xl font-semibold mt-4">
            Data Science vs. Data Analytics: The Key Differences You Need to
            Know
          </h2>

          {/* Blog content */}
          <p className="text-gray-700 mt-2" style={paragraphStyles}>
            In today’s data-driven world, Data Science and Data Analytics have
            become two of the most sought-after fields. However, choosing the
            right path can be confusing. In this article, we’ll explore the key
            differences between Data Science and Data Analytics, focusing on
            their scope, goals, job roles, necessary skills, and potential
            salaries. By the end, you’ll have a clearer understanding of which
            career path might suit you better.
          </p>

          {/* Next page remaining blog */}
          <button
            className="text-blue-500 mt-4 inline-block font-medium hover:underline"
            onClick={() =>
              handleShowForm(
                blog1_img,
                "Data Science vs. Data Analytics: The Key Differences You Need to Know"
              )
            }
          >
            Read More →
          </button>
         
        </div>

        {/* Blog 2 */}
        <div className="bg-white shadow-md rounded-lg p-4 flex-1">
          <img
            src={blog2_img}
            alt="not found"
            className="w-full h-64 object-cover rounded-md"
          />
          {/* Title */}
          <h2 className="text-xl font-semibold mt-4">
            Data Science CV Mistakes: Why 90% of Candidates Don’t Get
            Shortlisted
          </h2>

          {/* Content */}
          <p
            style={
              isOpen
                ? { ...paragraphStyles, WebkitLineClamp: "none" }
                : paragraphStyles
            }
          >
            From highlighting irrelevant experience to missing out on key
            industry skills, this article explores the pitfalls you should avoid
            and offers actionable tips to boost your chances of landing that
            interview. Learn how to craft a CV that stands out in the
            competitive field of data science.
          </p>

          {/* Toggle read more/less */}
          <button
            className="text-blue-500 mt-4 inline-block font-medium hover:underline"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? "Read Less..." : "Read More..."}
          </button>
        </div>
      </div>

      {/* Sidebar Section */}
      <div className="lg:w-1/3 w-full lg:pl-6 mt-6 lg:mt-0 cursor-pointer">
        <div className="bg-white shadow-md rounded-lg p-4">
          <h3 className="text-xl font-semibold mb-4">Our Services</h3>
          <ul className="space-y-2">
            <li>
            <span className="text-blue-500 hover:underline"
              onClick={()=>navigate("/mockinterview")}>
                Book a Mock Interview
              </span>
            </li>
            <li>
            <span className="text-blue-500 hover:underline"
              onClick={()=>navigate("/cvreview")}>
                Book a CV Review
              </span>
            </li>
            <li>
              <span className="text-blue-500 hover:underline"
              onClick={()=>navigate("/careerGuidance")}>
                Get a Career Guidance
              </span>
            </li>
          </ul>
        </div>
      </div>
      </div>
      <Footer/>
    </div>
  );
}

export default BlogComponent;
