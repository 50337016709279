import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CareerGuidanceMentor = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
    .get("/db.json")
    .then((response) => {
      setData(response.data.mentors); 
    })
    .catch((error) => console.error("Error fetching mentors:", error));
  }, []);

  const handleShowForm = (id, fees) => {
    navigate(`/form2/${id}`, { state: { flag: 2, fees } });
  };

  return (
    <div className="bg-black text-white pt-10 pb-10">
      {/* Header Section */}
      <div className="text-center px-4">
        <h2 className="text-lg md:text-2xl font-semibold">
          Select Mentor for Career Guidance
        </h2>
        <div className="flex justify-center mt-2">
          <div className="h-1 w-16 md:w-32 bg-cyan-500"></div>
        </div>
      </div>

      {/* Cards Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-4 sm:px-8 md:px-16 mt-8">
        {data.map((d, i) => (
          <div
            key={i}
            className="flex flex-col bg-gray-800 p-4 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300"
          >
            {/* Top Section: Photo + Details */}
            <div className="flex flex-col sm:flex-row sm:items-center">
              {/* Profile Photo */}
              <div className="flex-shrink-0 mb-4 sm:mb-0 sm:mr-4">
                <img
                  className="rounded-lg shadow-lg w-24 h-24 md:w-32 md:h-32 object-cover"
                  src={d.img_url}
                  alt="profile"
                />
              </div>

              {/* Details Section */}
              <div className="flex flex-col justify-between sm:ml-4">
                <div>
                  <h2 className="text-sm md:text-lg font-bold">{d.name}</h2>
                  <p className="text-xs md:text-sm text-gray-400">
                    {d.position}
                  </p>
                  <p className="text-xs md:text-sm text-gray-400">
                    {d.experience}
                  </p>

                  {/* About */}
                  <p className="mt-2 text-xs md:text-sm text-gray-300">
                    {d.about}
                  </p>
                </div>
              </div>
            </div>

            {/* Bottom Section: Technologies, Price, and Button */}
            <div className="mt-4 pt-4">
              {/* Technologies */}
              <div className="flex flex-wrap gap-2">
                {d.technologies.map((tech, index) => (
                  <span
                    className="px-4 py-1 text-xs md:text-sm bg-gray-700 text-gray-300 rounded-full"
                    key={index}
                  >
                    {tech}
                  </span>
                ))}
              </div>

              {/* Price and Button */}
              <div className="mt-4 flex justify-between items-center">
                <div className="text-sm sm:text-lg font-bold text-cyan-400">
                  &#8377; {Math.ceil(d.price * (2 / 3))} / 30 mins Session
                </div>
                <button
                  className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition-all duration-300"
                  onClick={() => handleShowForm(d.id, Math.ceil(d.price * (2 / 3)))}
                >
                 Schedule a Career Guidance Mentor
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CareerGuidanceMentor;
