import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  let dropdownTimer;

  const handleMouseEnter = () => {
    clearTimeout(dropdownTimer); 
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    dropdownTimer = setTimeout(() => {
      setIsDropdownOpen(false);
    }, 400); 
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <header className="w-full fixed top-0 left-0 z-50 bg-black shadow-md">
      <nav className="flex justify-between items-center p-3">
        <div className="text-2xl text-white font-bold ml-6">inttrvuprep</div>
        <ul className="hidden md:flex items-center">
          <li className="px-4 text-xl">
            <Link
              to="/"
              className="text-white hover:text-yellow-200 transition-colors"
            >
              Home
            </Link>
          </li>
          <li className="px-4 text-xl">
            <Link
              to="/content"
              className="text-white hover:text-yellow-200 transition-colors"
            >
              Content
            </Link>
          </li>
          <li
            className="relative px-4 text-xl"
            onMouseEnter={handleMouseEnter} // Open on hover
            onMouseLeave={handleMouseLeave} // Close on mouse leave with delay
            onClick={toggleDropdown} // Toggle on click
          >
            <span className="text-white hover:text-yellow-200 transition-colors cursor-pointer">
              Services
            </span>
            {/* Dropdown Menu */}
            {isDropdownOpen && (
              <ul className="absolute left-0 mt-2 bg-gray-700 text-white shadow-lg rounded-lg overflow-hidden z-20">
                <li
                  className="px-4 py-2 hover:bg-gray-600 cursor-pointer"
                  onClick={() => navigate("/mockinterview")}
                >
                  Mock Interview
                </li>
                <li
                  className="px-4 py-2 hover:bg-gray-600 cursor-pointer"
                  onClick={() => navigate("/cvreview")}
                >
                  CV Review
                </li>
                <li
                  className="px-4 py-2 hover:bg-gray-600 cursor-pointer"
                  onClick={() => navigate("/careerguidance")}
                >
                  Career Guidance
                </li>
              </ul>
            )}
          </li>
          <li className="px-4 text-xl">
            <Link
              to="/about"
              className="text-white hover:text-yellow-200 transition-colors"
            >
              About Us
            </Link>
          </li>
          <li className="px-4 text-xl">
            <Link
              to="/blogs"
              className="text-white hover:text-yellow-200 transition-colors"
            >
              Blog
            </Link>
          </li>
          <li className="px-4 text-xl">
            <Link
              to="/contact"
              className="text-white hover:text-yellow-200 transition-colors"
            >
              Contact Us
            </Link>
          </li>
        </ul>

        {/* Mobile Menu */}
        <div className="md:hidden">
          <button
            onClick={toggleDropdown}
            className="text-white focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
      </nav>

      {/* Mobile Dropdown Menu */}
      {isDropdownOpen && (
        <div className="absolute left-0 right-0 top-16 bg-black text-white py-4 md:hidden">
          <ul className="flex flex-col items-center">
            <li className="px-4 py-2 hover:bg-gray-600">
              <Link to="/" className="text-white hover:text-yellow-200">
                Home
              </Link>
            </li>
            <li className="px-4 py-2 hover:bg-gray-600">
              <Link to="/content" className="text-white hover:text-yellow-200">
                Content
              </Link>
            </li>
            <li
              className="px-4 py-2 hover:bg-gray-600"
              onClick={toggleDropdown}
            >
              <span className="text-white hover:text-yellow-200 cursor-pointer">
                Services
              </span>
            </li>
            <li className="px-4 py-2 hover:bg-gray-600">
              <Link to="/about" className="text-white hover:text-yellow-200">
                About Us
              </Link>
            </li>
            <li className="px-4 py-2 hover:bg-gray-600">
              <Link to="/blog" className="text-white hover:text-yellow-200">
                Blog
              </Link>
            </li>
            <li className="px-4 py-2 hover:bg-gray-600">
              <Link to="/contact" className="text-white hover:text-yellow-200">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;
