import React from "react";
import Reviews from "./Reviews";
import Companies from "./Companies";
import Footer from "./Footer";
import OverlappingCards from "./OverlappingCards";
import DynamicCard from "./DynamicCard";
import FrontPage from "./FrontPage";
import MentorBenefits from "./MentorBenefits";


const Home = () => {
  return (
    <div className="w-full overflow-hidden">
  <FrontPage />
  <OverlappingCards />
  <DynamicCard />
  <MentorBenefits />
  <Reviews />
  <Companies />
  <Footer />
</div>

  );
};

export default Home;
