import React from "react";
import aboutUs_img from "../assets/aboutus.jpg";
import Header from "./Header";
import Footer from "./Footer";
import { Mentor_img } from "../utils/constants";

import VisionSection from "./VisionSection";

const About = () => {
  return (
    <div>
      {/* Header */}
      <div>
        <Header />
      </div>

      {/* About Us Section */}
      <div className="relative h-[25rem] md:h-[35rem] mb-20">
        {/* Image */}
        <img
          className="w-full h-full mt-14 object-cover"
          src={aboutUs_img}
          alt="Background"
        />
        {/* Overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

        {/* Content */}
        <div className="absolute inset-0 flex items-center px-4 md:px-16">
          <div className="text-white max-w-2xl">
            <h2 className="text-3xl md:text-5xl font-bold mb-4">About Us</h2>
            <p className="text-base md:text-lg">
              At INTRVU, we go beyond teaching—we mentor. Founded by industry
              experts, we specialize in personalized guidance to help you
              succeed at every step of your journey.
            </p>
          </div>
        </div>
      </div>

      {/* Founder Section */}
      <div className="px-4 md:px-32 mb-20">
        <div className="flex flex-col md:flex-row items-center md:items-start gap-8 md:gap-20">
          {/* Image Section */}
          <div className="flex flex-col items-center">
            <img
              src={Mentor_img}
              alt="Not Found"
              className="w-40 h-40 md:w-64 md:h-64 object-cover mb-4"
            />
            <div className="text-center mt-2 text-black">
              <p className="text-base font-semibold">Name</p>
              <p>Founder & CEO of Inttrvu.Ai</p>
            </div>
          </div>

          {/* Content Section */}
          <div className="flex flex-col text-center md:text-left">
            <h2 className="mb-4 text-lg md:text-xl font-semibold">
              Hello, I am ABC, ex-Chief Data Scientist from Barclays. I am
              Founder and CEO of INTTRVU.ai
            </h2>
            <p className="mb-4 text-base md:text-lg">
              I have completed my Masters from Technical University of
              Darmstadt, Germany with a focus on Computer Vision and Machine
              Learning. I have published many patents based on my research work
              in this domain. I have 10+ years of professional experience in
              Data Science. I have hired, led, and built Data Science teams
              from scratch.
            </p>
            <p className="text-base md:text-lg">
              I know exactly what it takes to become an expert Data Scientist
              and crack Data Science interviews. I have designed both programs
              based on my experience of 10+ years. I have kept both programs
              practical-oriented with lots of hands-on learning.
            </p>
          </div>
        </div>
      </div>

      {/* Vision and Footer Section */}
      <div>
        <VisionSection />
        <Footer />
      </div>
    </div>
  );
};

export default About;
