import React, { useState, useRef, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import MentorCard from "./MentorCard";
import DateCalendar from "./DateCalendar";

const Form2 = () => {
  const location = useLocation();
  const { id } = useParams();
  const { flag, fees } = location.state || {};
  const navigate = useNavigate();

  const [showCard, setShowCard] = useState(false);
  const [slideIn, setSlideIn] = useState(false);
  const dynamicCardRef = useRef(null);

  // Check if the device is mobile
  const isMobile = useMediaQuery({ maxWidth: 768 });

  // Trigger sliding animation on mobile devices
  const handleShowCard = () => {
    if (isMobile) {
      setSlideIn(true); 
    }
    setShowCard(flag);
  };

  useEffect(() => {
    if (showCard && dynamicCardRef.current) {
      dynamicCardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [showCard]);

  const renderComponent = () => {
    switch (flag) {
      case 1:
        return "/cvreview";
      case 2:
        return "/careerguidance";
      case 3:
        return "/mockinterview";
      default:
        return null;
    }
  };

  if (isMobile) {

    return (
      <div className="bg-white min-h-screen">
        {/* Mentor Card */}
        <div className="flex flex-col items-center">
          <div className="w-full max-w-lg">
            <MentorCard id={id} prices={fees} />
          </div>
        </div>


        {/* Date Calendar */}
        <div className="flex flex-col items-center p-4">
          <div className="w-full max-w-lg">
            <DateCalendar id={id} prices={fees} onShowCard={handleShowCard} />
          </div>
        </div>
      </div>

    );
  }

  return (
  
    <div className="bg-gray-800 min-h-screen">
      {/* Main Content Section */}
      <div className="flex flex-col md:flex-row items-start justify-center p-6 space-y-8 md:space-y-0 md:space-x-8 pt-20">
        {/* Mentor Card Section */}
        <div className="w-full md:w-5/12 flex justify-center md:justify-end">
          <div className="w-full max-w-lg bg-white p-6">
            <MentorCard id={id} prices={fees} />
          </div>
        </div>

        {/* Date Calendar Section */}
        <div
          className={`w-full md:w-6/12 flex justify-center ${
            slideIn ? "slide-in" : ""
          }`}
        >
          <div className="w-full max-w-lg bg-white p-6">
            <DateCalendar id={id} prices={fees} onShowCard={handleShowCard} />
          </div>
        </div>
      </div>

      {/* Dynamic Card Section */}
      {showCard && (
        <div ref={dynamicCardRef} className="mt-12">
          {navigate(renderComponent())}
        </div>
      )}
    </div>
  );
};

export default Form2;
