import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />

      <div className="flex-grow bg-white py-28 px-4 md:px-16 lg:px-32">
        {/* Heading */}
        <h1 className="text-2xl md:text-3xl font-bold text-black mb-10 text-center">
          Privacy & Refund Policy
        </h1>

        {/* Content */}
        <div className="text-black text-base md:text-lg leading-relaxed space-y-8">
          <p>
            Welcome to{" "}
            <span className="font-semibold">https://inttrvuprep.com</span>,
            owned and managed by{" "}
            <span className="font-semibold">INTTRVU LEARNING PLATFORM LLP</span>{" "}
            (hereinafter referred to as "INTTRVU," "us," or "we"). By browsing
            and using this website, you agree to comply with and be bound by the
            following terms and conditions of use, which, together with our
            Privacy Policy, govern INTTRVU’s relationship with you in relation
            to this website.
          </p>
          <p>
            The term ‘<span className="font-semibold">INTTRVU</span>’ refers to
            the owner of the website, whose registered office is{" "}
            <span className="font-semibold">
              Grand View 7, Phase 3, A707, Pune 411046, India
            </span>
            , with company registration number{" "}
            <span className="font-semibold">ACB-9489</span>.
          </p>
          <p>
            The term ‘<span className="font-semibold">you</span>’ refers to the
            user or viewer of our website.
          </p>

          {/* Terms of Use*/}
          <div>
            <h2 className="text-lg md:text-xl font-bold mb-4">Terms of Use:</h2>
            <div className="space-y-6 pl-6">
              <div>
                <h3 className="font-semibold">1. Website Content</h3>
                <ul className="list-disc pl-10">
                  <li>
                    The content on this website is for general information and
                    use only and is subject to change without notice.
                  </li>
                  <li>
                    Neither we nor any third parties provide any warranty or
                    guarantee regarding the accuracy, timeliness, performance,
                    completeness, or suitability of the information and
                    materials on this website for any particular purpose. You
                    acknowledge that such information may contain inaccuracies
                    or errors, and we expressly exclude liability for any such
                    inaccuracies or errors to the fullest extent permitted by
                    law
                  </li>
                  <li>
                    Your use of any information or materials on this website is
                    entirely at your own risk. It is your responsibility to
                    ensure that any products, services, or information meet your
                    specific requirements.
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="font-semibold">2. Intellectual Property</h3>
                <ul className="list-disc pl-10">
                  <li>
                    All materials on this website, including the design, layout,
                    look, appearance, and graphics, are owned by or licensed to
                    INTTRVU. Reproduction is prohibited except in accordance
                    with the copyright notice.
                  </li>
                  <li>
                    Unauthorized use of this website or its content may result
                    in a claim for damages and/or criminal offense.
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="font-semibold">3. External Links</h3>
                <ul className="list-disc pl-10">
                  <li>
                    This website may contain links to other websites for your
                    convenience. These links do not signify endorsement, and
                    INTTRVU is not responsible for the content of linked
                    websites.
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="font-semibold">4. Restrictions on Linking</h3>
                <ul className="list-disc pl-10">
                  <li>
                    You may not create a link to this website from another
                    website or document without prior written consent from
                    INTTRVU.
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="font-semibold">5. Laws Governing Use</h3>
                <ul className="list-disc pl-10">
                  <li>
                    Your use of this website and any disputes arising from it
                    are subject to the laws of India.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/*Cancellation and Refund Policies*/}
          <div>
            <h2 className="text-lg md:text-xl font-bold mb-4">
              Cancellation and Refund Policies
            </h2>

            <div className="space-y-6 pl-6">
              <div>
                <h3 className="font-semibold">1. General Cancellations</h3>
                <ul className=" pl-6">
                  <li>
                    No cancellations are entertained for the products or courses
                    purchased/booked through INTTRVU.
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="font-semibold">2. Refund Policy</h3>
                <ul className=" pl-6">
                  <li>
                    We do not have a refund policy for any products, courses, or
                    services.
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="font-semibold">3. Mentor Session Policy</h3>
                <ul className="list-disc pl-10">
                  <li>
                    Charges for booking mentor sessions are non-refundable under
                    any circumstances.
                  </li>
                  <li>
                    {" "}
                    Clients must select a suitable time for the session during
                    the booking process. Once a time slot is confirmed, it
                    cannot be changed.
                  </li>
                  <li>
                    Clients are expected to attend the session at the selected
                    time. Failure to attend will result in the session being
                    considered completed with no rescheduling or refund
                    provided.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/*Privacy Policy*/}
          <div>
            <h2 className="text-lg md:text-xl font-bold mb-4">
              Privacy Policy
            </h2>
            <p>
              This privacy policy sets out how INTTRVU uses and protects any
              information you provide while using this website.
            </p>
            <div className="space-y-6 pl-6">

              <div>
                <h3 className="font-semibold">1. Information Collected</h3>
                <ul className=" pl-6">
                  <li>
                    <h2> We may collect the following information: </h2>
                    <ul className="list-disc pl-10">
                      <li>Name and job title</li>
                      <li>
                        Contact information, including email address and phone
                        number
                      </li>
                      <li>
                        Demographic information, such as city, postcode,
                        preferences, and interests
                      </li>
                      <li>
                        Other information relevant to inquiries, surveys, or
                        offers
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="font-semibold">2. Usage of Information</h3>
                <ul className=" pl-6">
                  <li>
                    <h2> We use the information for: </h2>
                    <ul className="pl-10 list-disc">
                      <li>Internal record keeping</li>
                      <li>Improving our products and services</li>
                      <li>
                        Periodic promotional communications about products,
                        offers, or information we find useful
                      </li>
                      <li>Feedback and market research</li>
                    </ul>
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="font-semibold">3. Security</h3>
                <ul className=" pl-6">
                  <li>
                    <h2>
                      {" "}
                      We are committed to ensuring your information is secure
                      with appropriate safeguards in place to prevent
                      unauthorized access or disclosure.
                    </h2>
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="font-semibold">4. Cookies</h3>
                <ul className=" pl-6">
                  <li>
                    <h2>
                      {" "}
                      Cookies help us analyze web traffic and customize our
                      website to better serve you. While cookies do not give us
                      access to your computer, they collect data you choose to
                      share. You may modify browser settings to decline cookies,
                      but this may limit website functionality.{" "}
                    </h2>
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="font-semibold">5. Third-Party Links</h3>
                <ul className=" pl-6">
                  <li>
                    <h2>
                      {" "}
                      Our website may include links to external sites, but we
                      are not responsible for their privacy practices or
                      content.
                    </h2>
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="font-semibold">
                  6. Controlling Your Information
                </h3>
                <ul className=" pl-6">
                  <li>
                    <h2>
                      You may restrict the collection or use of your
                      information. For inquiries or to opt out of promotional
                      communications, contact
                      <span className="font-semibold">info@inttrvu.ai .</span>
                    </h2>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Content Ownership and Copyright */}
          <div>
            <h2 className="text-lg md:text-xl font-bold mb-4">
              Content Ownership and Copyright
            </h2>
            <div className="space-y-6 pl-6">
              <div>
                <h3 className="font-semibold">1. Copyright Restrictions</h3>
                <ul className="list-disc pl-10">
                  <li>
                    Copying, distributing, reproducing, or modifying INTTRVU
                    content without permission is prohibited. Violations will be
                    subject to legal action under applicable copyright laws.
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="font-semibold">2. Third-Party Services</h3>
                <ul className="list-disc pl-10">
                  <li>
                    INTTRVU may share your data with third-party service
                    providers to facilitate services. We assume no
                    responsibility for the privacy practices or content of
                    third-party websites.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Service Modifications */}
          <div>
            <h2 className="text-lg md:text-xl font-bold mb-4">
              Service Modifications
            </h2>
            <div className="space-y-6 pl-6">
              <div>
                <ul className="list-disc pl-10">
                  <li>
                    1. INTTRVU reserves the right to add, change, suspend, or
                    discontinue services at its sole discretion.
                  </li>
                  <li>
                    2. Continued use of INTTRVU services after changes
                    constitutes acceptance of updated terms.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Disclaimer */}
          <div>
            <h2 className="text-lg md:text-xl font-bold mb-4">Disclaimer</h2>
            <div className="space-y-6 pl-6">
              <div>
                <h3 className="font-semibold">1. No Job Guarantee</h3>
                <ul className=" pl-6">
                  <li>
                    We do not provide a job guarantee. Career outcomes depend on
                    individual effort and market conditions.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Contact Information */}
          <div>
            <h2 className="text-lg md:text-xl font-bold mb-4">
              Contact Information
            </h2>
            <div className="pl-6">
            <p>
              For questions or concerns regarding this policy, contact us at:
              <br />
              <strong>Acharya House</strong>, Plot No.24 Sr.No.19/12/1, Bavdhan,
              Pune, Maharashtra 411021
            </p>
            <p>
              Email: <strong>info@inttrvu.ai</strong>
              <br />
              Tel.: <strong>+91 9307853699</strong>
            </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
